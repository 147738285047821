<template>
  <page-container>
  </page-container>
</template>

<script>
import { wxJsPay } from '@/util/wxJsPay';
import { Toast } from 'vant';

export default {
  name: 'pcWxQrCodePay',
  created() {
    let { orderNo = '' } = this.$route.query;
    wxJsPay({ id: orderNo }, 'pcQrCodeToWxPay').then(res => {
      if (res) {
        Toast('微信支付成功');
      }
    });
  }
};
</script>

<style scoped>

</style>
